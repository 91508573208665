module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Safeline Electricals ","short_name":"safeline","start_url":"/","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"acfe274d28c806bf7841f36112c46a54"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
